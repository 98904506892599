import React,{useRef,useState,useEffect} from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Header from './components/Header';
import Main from './components/Main';

const useStyles = makeStyles((theme)=>({
  App:{
    boxSizing:"border-box",
    backgroundImage: `url("images/FullBG.png")`,
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    width: '100%',
    
    backgroundPosition:'center'
  },
  main:{
    // marginBottom:'300px',
  }
}));


function App() {

  const [mobile,setmobile] = useState(true);

  useEffect(()=>{
    function handleResize() {
      if(window.innerWidth>=1000){
         setmobile(false);
      }
     }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[])

    

  const classes = useStyles();
  const about = useRef(null);
  const roadmap = useRef(null);
  const sales = useRef(null);
  const team = useRef(null);

  useEffect(() => {
    // Update the document title using the browser API
   
    
  },[]);

  return (
    <div className={classes.App}>
      {
        mobile?(
      <div>
        <Header about={about} roadmap={roadmap}
          sales={sales} team={team}/>
        <Main className={classes.main} about={about} roadmap={roadmap}
          sales={sales} team={team}
        />
      </div>
        ):
        (
          <Container maxWidth="xl">
            <Header about={about} roadmap={roadmap}
              sales={sales} team={team}/>
            <Main className={classes.main} about={about} roadmap={roadmap}
              sales={sales} team={team}
            />
          </Container>
        )
      }
      
    </div>
  );
}

export default App;
                                                                