import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core';
import Countdown from "react-countdown";
import moment from 'moment-timezone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  connectWallet,
  getCurrentWalletConnected,
  checkMintStatus, 
  getMintedAmountFromContract
} from '../utils/interact';
import { ethers } from 'ethers';
import ABI from '../abis/Wickens.json';

import { css } from "@emotion/react";
import BounceLoader from "react-spinners/ClipLoader";

const contractAddress = "0x61c0f01a77dbb995346e3e508575670ec49b5615";
const abi = ABI;

const useStyles = makeStyles((theme) => ({
  Smscontainer: {
    flexDirection: 'column',
    marginTop: '60px',
    width: '70%',
    margin: 'auto',
    '@media (max-width:1000px)': {
      width: '100%',
      marginTop: '20px',
    },
  },
  smsText1: {
    fontFamily: "boogaloo",
    color: '#efeee2',
    fontSize: '35pt',
    width: '90%',
    margin: 'auto',
    color: '#a3ada9',
    '@media (max-width:1600px)': {
      fontSize: '25pt',
    },
    '@media (max-width:1200px)': {
      fontSize: '20pt',
    },
    '@media (max-width:1000px)': {
      fontSize: '9pt',
    },
  },
  smsText2: {
    fontFamily: "boogaloo",
    color: '#efeee2',
    fontSize: '35pt',
    margin: 'auto',
    marginTop: '50px',
    width: '120px',
    height: '60px',
    background: '#58828C',
    borderRadius: '10px',
    textAlign: 'center',
    cursor: 'pointer',
    '@media (max-width:1600px)': {
      fontSize: '31pt',
    },
    '@media (max-width:1200px)': {
      fontSize: '23pt',
    },
    '@media (max-width:900px)': {
      display: '15px',
      marginTop: '10px',
      height: '40px'
    },
  },
  smsText3: {
    fontFamily: "boogaloo",
    color: '#efeee2',
    fontSize: '35pt',
    margin: 'auto',
    marginTop: '50px',
    width: '150px',
    height: '60px',
    background: '#58828C',
    borderRadius: '10px',
    textAlign: 'center',
    cursor: 'pointer',
    '@media (max-width:1600px)': {
      fontSize: '31pt',
    },
    '@media (max-width:1200px)': {
      fontSize: '23pt',
    },
    '@media (max-width:900px)': {
      width: '120px',
      display: '15px',
      marginTop: '10px',
      height: '40px'
    },
  },
  inputcontainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
    justifyContent: 'space-between',
    '@media (max-width:900px)': {
      marginTop: '0px',
    },
  },
  inputText1: {
    fontFamily: "boogaloo",
    width: '50px',
    height: "55px",
    background: '#a3ada9',
    borderWidth: '7px',
    borderColor: '#588278',
    borderRadius: '15px',
    borderStyle: 'solid',
    marginTop: '20px',
    margin: 'auto',
    fontSize: '40px',
    textAlign: 'center',
    '@media (max-width:1000px)': {
      width: '22px',
      height: '26px',
      borderWidth: '3px',
      borderColor: '#588278',
      borderRadius: '1px',
      fontSize: '20px',
    },
  },
  inputText2: {
    fontFamily: "boogaloo",
    width: '87%',
    height: "60px",
    borderRadius: '10px',
    borderWidth: '7px',
    background: '#a3ada9',
    borderColor: '#588278',
    fontSize: '5vw',
    textAlign: 'center',
    borderStyle: 'solid',
    alignSelf: 'center',
    marginTop: '20px',
    margin: 'auto',
    '@media (max-width:1000px)': {
      width: '120px',
      height: '30px',
      marginTop: '10px',
      borderRadius: '7px',
      borderWidth: '3px',
      borderColor: '#588278',
    },
  },
  inputText3: {
    fontFamily: "boogaloo",
    width: '87%',
    height: "53px",
    borderRadius: '10px',
    borderWidth: '7px',
    background: '#a3ada9',
    borderColor: '#588278',
    fontSize: '40px',
    textAlign: 'center',
    borderStyle: 'solid',
    alignSelf: 'center',
    marginTop: '20px',
    margin: 'auto',
     '@media (max-width:1400px)': {
      width: '89%',
    },
 
    '@media (max-width:990px)': {
      width: '120px',
      height: '30px',
      marginTop: '10px',
      borderRadius: '7px',
      borderWidth: '3px',
      borderColor: '#588278',
      fontSize: '20px',
    },
  },
  combdiv: {
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    marginTop: "20px"
  },
  combo: {
    width: '130px',
    height: '50px',
    fontSize: '35px',
    alignSelf: 'center',
    fontFamily: "boogaloo",
    textAlign: 'center',
    background: '#a3ada9',
    borderColor: '#588278',
    borderWidth: '5px',
    borderRadius: '5px',
    '@media (max-width:1000px)': {
      width: '55px',
      height: '30px',
      fontSize: '25px',
    },
  },
  amount: {
    marginRight: '10px',
    fontFamily: "boogaloo",
    fontSize: '40px',
    '@media (max-width:1000px)': {
      width: '55px',
      height: '30px',
      fontSize: '23px',
      alignSelf: 'center'
    },
  }
}));

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #fff;
`;

export default function Smscontainer() {

  const classes = useStyles();
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [price, setPrice] = useState(0);
  const [mintPreStatus, setPreMintStatus] = useState(true);
  const [mintPubStatus, setPubMintStatus] = useState(false);
  const [nftCount, setNFTCount] = useState(3);
  const [mintedAmount, setminted] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  useEffect(() => {
    const start = async () => {
      const { address, status } = await getCurrentWalletConnected();
      setWallet(address)
      setStatus(status);
      addWalletListener();
      if (address.length > 0) {
        getContractInfo()
        const interval = setInterval(async () => {
          getContractInfo()
        }, 10000);

        return () => clearInterval(interval)
      }else{
        console.log("wallet is not connected, but minted amount will be shown" )
        const {getTotalMintedAmount} = await getMintedAmountFromContract();
        setminted(getTotalMintedAmount.toString()); 
      }
    }
    
    start()
  }, []);

  const getContractInfo = async () => {     
    try {
      const { getPrice, getPreMintStatus, getPubMintStatus, getTotalMint } = await checkMintStatus();
      setPrice(getPrice.toString());    
      setPreMintStatus(getPreMintStatus);
      setPubMintStatus(getPubMintStatus);
      setminted(getTotalMint.toString());  
    } catch (error) {
      toast.error("Network is not correct! Please select Ethereum Mainnet on Metamask.");
    }    
  };

  const connectWalletPressed = async () => {
    if (walletAddress.length > 0) {
      setStatus("");
      setWallet("");
    } else {
      const walletResponse = await connectWallet();
      setStatus(walletResponse.status);
      setWallet(walletResponse.address);
      getContractInfo();
    }
  };

  const _onChangeNum = (val) => {
    setNFTCount(val);
  }

  const onMintPressed = async () => {
    if(mintPreStatus == true || mintPubStatus == true){
      try {
        const { ethereum } = window;
  
        if (ethereum) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          await ethereum.enable();
  
          const signer = provider.getSigner();
          const nftContract = new ethers.Contract(contractAddress, abi, signer);
  
          let pay_amount = nftCount * price / 1000000000000000000;
  
          let nftTxn = await nftContract.mint(nftCount, { value: ethers.utils.parseEther(pay_amount.toString())});
          // let nftTxn = await nftContract.mint(nftCount, { value: ethers.utils.parseEther(pay_amount.toString()) , gasLimit: '0'});
  
          setIsLoading(true);
          await nftTxn.wait();
          setIsLoading(false);
  
          toast.success("Transaction has been successfully!");
        } else {
          toast.error("Ethereum object does not exist");
        }
      } catch (err) {
        try {
          var msg = err.error.message.split(': ');
          console.log(msg);
          toast.error(msg[1]);
        } catch (error) {
        }
        
      }      
    }else{
      toast.warning("Sale is not active");
    }    
  };

  const Completionist = () => <span></span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <>
          <div className={classes.inputText1}>{days}</div>
          <div className={classes.inputText1}>{hours}</div>
          <div className={classes.inputText1}>{minutes}</div>
          <div className={classes.inputText1}>{seconds}</div>
        </>
      );
    }
  };
  var NewYork = moment.tz("2021-12-18 07:00", "America/New_York");

  return (
    <div className={classes.Smscontainer}>
      <ToastContainer />
      <p className={classes.smsText1}>SOMETHING WICKEN THIS WAY COMES...</p>
      <div className={classes.inputcontainer}>
        <Countdown date={new Date(NewYork.format()).getTime()} renderer={renderer} />
      </div>
      <div className={classes.inputText3}>{`${mintedAmount}/6666 SOLD`}</div>
      <div className={classes.combdiv}>
        <text className={classes.amount} >Amount</text><input className={classes.combo} min="1" max="10" type={'number'} defaultValue={3} onChange={(e) => _onChangeNum(e.target.value)} />
      </div>
      {
        walletAddress.length > 0 ? (
          <>
            <div>
              {isLoading == true ? (
                <div className={classes.smsText2} style={{}}>
                  <BounceLoader color="#fff" loading={isLoading} css={override} size={50} />
                </div>
              ) : (
                <div className={classes.smsText2} onClick={onMintPressed}>Mint</div>
              )}
            </div>
          </>
        ) : (
          <div className={classes.smsText3} onClick={() => connectWalletPressed()}>Connect</div>
        )
      }
    </div>
  )
}
