import React from 'react'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme)=>({
    image:{
        alignSelf:'center',
        justifySelf:'center'
    }
}));


export default function Monster({source,width}) {

    const classes = useStyles();

    return (
        <div style={{display:'flex',justifyContent:'center'}}>
            <img  className={classes.image} style={{width:width}}  src={source} />
        </div>
    )
}
