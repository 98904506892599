import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import LogoArea from './LogoArea';
import MenuArea from './MenuArea';
import SocialLinkArea from './SocialLinkArea';
import ToggleMenuIcon from './ToggleMenuIcon';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import {
    connectWallet,
    getCurrentWalletConnected,
    // checkMintStatus 
} from '../utils/interact';

const useStyles = makeStyles((theme) => ({
    logo: {
        width: '45%',
        '@media (max-width:1500px)': {
            width: '40%',
        },
        '@media (max-width:1000px)': {
            width: '100%',
        },
    },
    menu: {
        display: 'flex',
        flexDirection: 'row',
        width: '55%',
        '@media (max-width:1000px)': {
            width: '100%',
            flexDirection: 'column',

        },
    },
    menuarea: {
        width: '100%',
        '@media (max-width:1500px)': {
            width: '100%',
        },
        '@media (max-width:1200px)': {
            width: '100%',
        },
        '@media (max-width:1000px)': {
            width: '87%',
            alignSelf: 'center',
        },
    },
    socialarea: {
        width: '20%',
        '@media (max-width:1000px)': {
            width: '100%',
            alignSelf: 'center',
        },
    },
    connect: {
        background: "#464964",
        borderColor: '#464964 ',
        borderRadius: '10px',
        position: 'absolute',
        right: '3vw',
        top: '170px',
        height: "70px",
        width: '320px',
        fontSize: '20px',
        '@media (max-width:1000px)': {
            top: '10px',
            width: '150px',
            fontSize: '12px',
            height: "30px",
            right: '35vw',
            zIndex: 10
        },
        color: 'white'
    }
}));

const Wrapper = styled.div`
    position: relative;
    width:100%;
    display:flex;
    flex-direction:row;
    margin-bottom: 20px;  
`

export default function Header({ about, roadmap, sales, team }) {

    const classes = useStyles();
    const [modalOpen, setmodalOpen] = useState(false);
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                    setStatus("👆🏽 Write a message in the text-field above.");
                } else {
                    setWallet("");
                    setStatus("🦊 Connect to Metamask using the top right button.");
                }
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
                    </a>
                </p>
            );
        }
    }

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 1000) {
                setmodalOpen(true);
            }
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const start = async () => {
            const { address, status } = await getCurrentWalletConnected();
            setWallet(address)
            setStatus(status);
            addWalletListener();
        }
        start()
    },[])

    const connectWalletPressed = async () => {
        if(walletAddress.length > 0){
            setStatus("");
            setWallet("");
        }else{
            const walletResponse = await connectWallet();
            setStatus(walletResponse.status);
            setWallet(walletResponse.address);
            // getContractInfo();
        }
    };

    return (
        <Wrapper>
            <Grid container>
                <div className={classes.logo} >
                    <LogoArea />
                </div>
                {
                    walletAddress.length > 0 ? (
                        <>
                            <button className={classes.connect} onClick={() => connectWalletPressed()}>
                                {
                                    "Wallet Connected: " +
                                    String(walletAddress).substring(0, 6) +
                                    "..." +
                                    String(walletAddress).substring(38)
                                }
                            </button>
                        </>
                    ) : (
                        <button className={classes.connect} onClick={() => connectWalletPressed()}>connect wallet</button>
                    )
                }
                <div className={classes.menu} >
                    <div className={classes.menuarea}>
                        <MenuArea modalOpen={modalOpen} about={about} roadmap={roadmap}
                            sales={sales} team={team} />
                    </div>
                    <div className={classes.socialarea}>
                        <SocialLinkArea />
                    </div>
                </div>

                {/* <ToggleMenuIcon modalOpen={modalOpen} setmodalOpen={setmodalOpen}/> */}
            </Grid>
        </Wrapper>
    )
}
