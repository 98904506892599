import React, { Component }  from 'react';
import { ethers } from 'ethers';
import ABI from '../abis/Wickens.json';

const contractAddress = "0x61c0f01a77dbb995346e3e508575670ec49b5615";
const abi = ABI;

export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const obj = {
                status: "👆🏽 Write a message in the text-field above.",
                address: addressArray[0],
            };
            return obj;
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
                    <p>
                        {" "}
                        🦊{" "}
                        <a target="_blank" href={`https://metamask.io/download.html`}>
                            You must install Metamask, a virtual Ethereum wallet, in your
                            browser.
                        </a>
                    </p>
                </span>
            ),
        };
    }
};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    status: "👆🏽 Write a message in the text-field above.",
                };
            } else {
                return {
                    address: "",
                    status: "🦊 Connect to Metamask using the top right button.",
                };
            }
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
                    <p>
                        {" "}
                        🦊{" "}
                        <a target="_blank" href={`https://metamask.io/download.html`}>
                            You must install Metamask, a virtual Ethereum wallet, in your
                            browser.
                        </a>
                    </p>
                </span>
            ),
        };
    }
};

export const checkMintStatus = async () => {
    try {
        const { ethereum } = window;
  
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            await ethereum.enable();

            const signer = provider.getSigner();
            const nftContract = new ethers.Contract(contractAddress, abi, signer);

            const obj = {
                getPrice: await nftContract.price(),
                getPreMintStatus: await nftContract.presaleActive(),
                getPubMintStatus: await nftContract.saleActive(),
                getTotalMint: await nftContract.totalSupply()
            };
            
            return obj;
        } else {
            console.log("Ethereum object does not exist");
        }
    } catch (err) {
        console.log(err);
    }
};

export const getMintedAmountFromContract= async () => {
    try {
        
            const provider = ethers.providers.getDefaultProvider();      
            const nftContract = new ethers.Contract(contractAddress, abi, provider);
            const obj = {                
                getTotalMintedAmount: await nftContract.totalSupply()
            };            
            return obj;        
    } catch (err) {
        console.log(err);
    }
};
