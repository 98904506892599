import React from 'react'
import { makeStyles } from '@material-ui/core';
import discord from '../images/ICONdiscord.png';
import twitter from '../images/ICONtwitter.png';
import wallet from '../images/opensea.svg';

const useStyles = makeStyles((theme)=>({
    socialcontainer:{
        display:'flex',
        width:'100%',
        flexDirection:'row',
        marginTop:"100px",
        '@media (max-width:1000px)':{
            position:'absolute',
            top:'10px',
            marginTop:"0px",
            right:'10px',
            justifyContent:'flex-end'
        }
    },
    socialIcon:{
        width:'33%',
        // margin:'0 1em',
        zIndex:'10',
        cursor:'pointer',
        alignSelf:'center',
        '@media (max-width:1536px)':{
            // margin:'0 0.2em',
        },
        '@media (max-width:1000px)':{
            width:'10%',
            height:'30px',
            // margin:'0 0.1em',
        }
    },
    socialIcon2:{
        width:'22%',
        // margin:'0 1em',
        zIndex:'10',
        cursor:'pointer',
        '@media (max-width:1536px)':{
            // margin:'0 0.2em',
        },
        '@media (max-width:1000px)':{
            width:'8%',
            height:'30px',
            
        }
    }
}))

export default function SocialLinkArea() {

    const classes = useStyles();

    return (
        <div className={classes.socialcontainer}>
            <img src={discord} className={classes.socialIcon} onClick={()=>window.open("https://discord.gg/4qBtbvANTt")}/>
            <img src={twitter} className={classes.socialIcon} onClick={()=>window.open("https://twitter.com/WickensNFT")}/>
            <img src={wallet} className={classes.socialIcon2} onClick={()=>window.open("https://opensea.io/collection/wickensnft")}/>
        </div>
    )
}
