import React from 'react'
import styled from 'styled-components'
import logo from '../images/Logo.png';
import { makeStyles } from '@material-ui/core';

const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
`
const useStyles = makeStyles((theme)=>({
    imgStyle:{
        width:'100%',
        height:"30%",
        marginTop:"50px",
        '@media (max-width:890px)':{
            width:'100px',
            height:'140px'
        },
        '@media (max-width:670px)':{
            width:'100px',
            height:'120px'
        },
        '@media (max-width:470px)':{
            width:'80%',
            height:'100px',
            alignSelf:'center',
            marginBottom:'20px'
        },
    }
}));

export default function LogoArea() {
    
    const classes = useStyles();

    return (
        <LogoContainer>
            <img src={logo} className={classes.imgStyle} alt="logo" />
        </LogoContainer>
    )
}
