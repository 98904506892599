import React from 'react'
import { Grid } from '@material-ui/core';
import Monster from './Monster';
import Smscontainer from './Smscontainer';
import cha1 from '../images/Character1.png';
import cha2 from '../images/Character2.png';
import cha3 from '../images/Character3.png';
import cha4 from '../images/Character4.png';
import cha5 from '../images/Character5.png';
import cha6 from '../images/Character6.png';
import cha7 from '../images/Character7.png';
import mothboy from '../images/Mothboy.png';
import dragboy from '../images/Dragonboy.png';
import melon from '../images/RarityImage.png';
import rycoon from '../images/Rycoon.png';
import shanoot from '../images/Shanoot.png';
import steve from '../images/Steve.png';
import insta from '../images/insta.png';
import discordIcon from '../images/ICONdiscord.png';
import twitterIcon from '../images/ICONtwitter.png'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme)=>({
    headermain:{
        '@media (max-width:1600px)':{
            marginTop:'30px',
        },
        '@media (max-width:1000px)':{
            marginTop:'0px',
        },
    },
    introcontainer:{
        display:'flex', 
        justifyContent:'center',
        flexDirection:'column',
        textAlign:'center'
    },
    introtext:{
        fontFamily:"boogaloo",
        color:'#efeee2',
        fontSize:'35pt',
        alignSelf:'center',
        marginTop:'40px',
        margin:'auto',
        '@media (max-width:1600px)':{
            fontSize:'31pt',
        },
        '@media (max-width:1200px)':{
            fontSize:'23pt',
        },
        '@media (max-width:900px)':{
            fontSize:'27pt',
         },
    },
    introbodyText:{
        fontFamily:'opensans',
        color:'#efeee2',
        fontSize:'18pt',
        alignSelf:'center',
        marginTop:'20px',
        '@media (max-width:1600px)':{
            fontSize:'13pt',
        },
        '@media (max-width:1200px)':{
            fontSize:'9pt',
        },
        '@media (max-width:900px)':{
            fontSize:'10pt',
         },
    },
    roadmap:{
        width:'100%',
        display:'flex', 
        justifyContent:'center',
        marginTop:'50px',
        margin:'auto',
        '@media (max-width:1000px)':{
            marginTop:'0px'
        }
    },
    card:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        marginTop:'30px',
        flexDirection:'column',
    },
    card5:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        marginTop:'30px',
        margin:'auto',
    },
    cardcontent:{
        display:'flex',
        alignItems:'center',
        textAlign:'center',
        flexDirection:"column",
        background:'#5e394c',
        borderRadius:'50px',
        paddingTop:'20px',
        paddingBottom:"20px",
        width:'100%',
        marginBottom:'30px',
        '@media (max-width:1000px)':{
           width:'100%',
           float:'right',
           marginTop:'20px',
           borderRadius:'20px',
           marginLeft:'0px',
           paddingTop:'10px',
           paddingRight:'0px',
           paddingLeft:'0px',
        },
        '@media (max-width:700px)':{
            
        },
    },
    cardcontent1:{
        display:'flex',
        alignItems:'center',
        textAlign:'center',
        flexDirection:"column",
        background:'#5e394c',
        borderRadius:'50px',
        paddingTop:'20px',
        paddingBottom:"20px",
        width:'100%',
        marginBottom:'30px',
        marginTop:'-11.5%',
        '@media (max-width:1000px)':{
           width:'100%',
           float:'right',
           marginTop:'20px',
           borderRadius:'20px',
           marginLeft:'0px',
           paddingTop:'10px',
           paddingRight:'0px',
           paddingLeft:'0px',
           marginTop:'0px'
        },
        '@media (max-width:700px)':{
            
        },
    },
    cardcontenttext1:{
        fontFamily:"boogaloo",
        color:'#efeee2',
        fontSize:'37pt',
        '@media (max-width:1600px)':{
            fontSize:'31pt',
        },
        '@media (max-width:1200px)':{
            fontSize:'23pt',
        },
        '@media (max-width:900px)':{
            fontSize:'18pt',
         },
    },
    cardcontenttext8:{
        fontFamily:"boogaloo",
        color:'#efeee2',
        fontSize:'37pt',
        '@media (max-width:1600px)':{
            fontSize:'31pt',
        },
        '@media (max-width:1200px)':{
            fontSize:'23pt',
        },
        '@media (max-width:900px)':{
            fontSize:'23pt',
         },
    },
    cardcontenttext2:{
        fontFamily:"opensans",
        color:'#efeee2',
        fontSize:'18pt',
        marginTop:'20px',
        '@media (max-width:1600px)':{
            fontSize:'13pt',
        },
        '@media (max-width:1200px)':{
            fontSize:'9pt',
        },
        '@media (max-width:1000px)':{
            fontSize:'10pt',
            marginTop:'15px',
            width:'95%'
         },
         
    },
    cardcontenttext22:{
        fontFamily:"opensans",
        color:'#efeee2',
        fontSize:'18pt',
        marginTop:'20px',
        paddingLeft:'27%',
        paddingRight:"27%",
        '@media (max-width:1600px)':{
            fontSize:'13pt',
        },
        '@media (max-width:1200px)':{
            fontSize:'9pt',
        },
        '@media (max-width:1000px)':{
            fontSize:'10pt',
            marginTop:'15px',
            width:'95%',
            paddingLeft:'0%',
            paddingRight:"0%",
         },
         
    },
    middleimage:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        width:'100%',
        zIndex: 1,
        marginTop:'-10%',
        '@media (max-width:1000px)':{
            display:"none"
        },
    },
    card1:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        flexDirection:"column",
        background:'#5e394c',
        borderRadius:'10px',
        marginTop:'-3%',
        margin:'auto',
        paddingTop:'100px',
        paddingLeft:'10px',
        paddingRight:'10px',
        '@media (max-width:1600px)':{
            marginTop:'-12%',
        },
        '@media (max-width:1200px)':{
            marginTop:'-13%',
            paddingTop:'20px',
        },
        '@media (max-width:1000px)':{
            marginTop:'5%',
            paddingTop:'10px',
            borderRadius:'5px',
         },
    },
    cardcontenttext3:{
        fontFamily:"opensans",
        color:'#efeee2',
        fontSize:'18pt',
        flexDirection:'column',
        marginTop:'10px',
        width:'100%',
        '@media (max-width:1600px)':{
            fontSize:'13pt',
        },
        '@media (max-width:1200px)':{
            fontSize:'9pt',
        },
        '@media (max-width:1000px)':{
            fontSize:'10pt',
            marginTop:'5px',
            width:'98%',
         },
    },
    card2:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        background:'#5e394c',
        borderRadius:'50px',
        marginLeft:'1vw',
        paddingTop:'20px',
        paddingLeft:'5px',
        paddingRight:'5px',
        textAlign:'center',
        marginTop:'20px',
        paddingBottom:"20px",
        '@media (max-width:900px)':{
            margin:'auto',
            marginTop:'20px',
            height:'450px'
        },
    },
    card6:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        background:'#5e394c',
        borderRadius:'50px',
        marginLeft:'1vw',
        paddingTop:'20px',
        paddingLeft:'5px',
        paddingRight:'5px',
        textAlign:'center',
        marginTop:'20px',
        paddingBottom:"20px",
        '@media (max-width:900px)':{
            margin:'auto',
            marginTop:'20px',
            
        },
    },
    card3:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        flexDirection:"column",
        margin:'auto',
        marginTop:'70px',
        '@media (max-width:900px)':{
            margin:'auto',
            marginTop:'0px',
        },
    },
    cardcontent7:{
        display:'flex',
        alignItems:'center',
        textAlign:'center',
        flexDirection:"column",
        background:'#5e394c',
        borderRadius:'50px',
        paddingRight:'20px',
        paddingLeft:'20px',
        paddingTop:'20px',
        margin:'auto',
        '@media (max-width:1000px)':{
            width:'100%',
            paddingTop:'10px',
            paddingBottom:'20px',
            paddingRight:'0px',
            paddingLeft:'0px',
        },
    },
    bottomimage:{
        justifyContent:'center',
        display:'flex',
        flexDirection:'row'
        // marginTop:'100px',
    },
    bottomText1:{
        display:"flex",
        flexDirection:"column",
        alignSelf:'start',
        alignItems:'center',
        '@media (max-width:1000px)':{
            display:'none'
        },
    },
    bottomText2:{
        display:"flex",
        flexDirection:"column",
        alignSelf:'end',
        alignItems:'center',
        '@media (max-width:1000px)':{
            display:'none'
        },
    },
    bottomText3:{
        display:'flex',
        alignItems:'center',
        flexDirection:'column',
        '@media (max-width:1000px)':{
            display:'flex',
            alignItems:'center',
            flexDirection:'column',
        },
    },
    bottomText4:{
        '@media (max-width:1000px)':{
            display:'flex',
            alignItems:'center',
            flexDirection:'column'
        },
    },
    bottomend:{
        display:'flex',
        justifyContent:'center',
        marginBottom:'500px',
        '@media (max-width:1000px)':{
            marginBottom:'50px',
        }
    },
    bottomcontent:{
        '@media (max-width:1000px)':{
            display:"flex",
            alignItems:'center'
        }
    },
    bottom:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        marginTop:'20px',
        '@media (max-width:1000px)':{
            flexDirection:'row',
        }
    }
 }));

export default function Main({about,roadmap,sales,team}) {

    const classes = useStyles();

    return (
        <>
            <Grid className={classes.headermain}  style={{ justifyContent:'center',alignItems:'center'}}  container direction={'row'}>
                <Grid item xl={4} lg={4} md={3} sm={4} xs={4}>
                    <Monster width={'75%'}  source={cha1} />
                </Grid>
                <Grid item xl={4} lg={4} md={6} sm={4} xs={4}>
                    <Smscontainer/>
                </Grid>
                <Grid item xl={4} lg={4} md={3} sm={4} xs={4}>
                    <Monster width={'75%'}  source={cha2} />
                </Grid>
            </Grid>
            <Grid className={classes.introcontainer} container item lg={12}>
                <Grid item lg={12}  className={classes.introtext} ref={about}>WHO ARE THE WICKENS?</Grid>
                <Grid item sm={10} xs={10} lg={6} className={classes.introbodyText}>Wickens is a limited collection of 6666 unique NFTs living on the Ethereum network. 
                They are randomly assembled from an assortment of over 160 traits, making each Wicken uniquely yours. Ownership of a Wicken grants you 
                ongoing benefits in the form of airdrops, physical redemptions, 
                as well as entry into various community events, games and giveaways.</Grid>
            </Grid>
            <Grid  style={{ justifyContent:'center',alignItems:'center',marginTop:'50px'}}  container direction={'row'}>
                <Grid  item xl={3} lg={3} md={3} sm={4} xs={4}>
                    <Monster width={'100%'} source={cha3} />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={4}>
                    <Monster width={'100%'} source={cha4} />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={4}>
                    <Monster width={'100%'} source={cha5} />
                </Grid>
            </Grid>
            <Grid container item className={classes.roadmap} xl={12} lg={12} md={12} sm={12} xs={12}   direction={'column'} ref={roadmap}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.introtext}>ROAD MAP</Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.card}>
                    <div className={classes.cardcontent}>
                        <text className={classes.cardcontenttext1}>10%</text>
                        <text className={classes.cardcontenttext22}>
                        As a welcome to our new residents of Edgewick, the first 666 NFT’s minted will go 
                        into the running for a <b>unique 1/1 Wickens NFT</b> as well as <b>3 original sketch NFTs of the OG Wickens.</b>
                        </text>
                    </div>
                    <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className={classes.middleimage}>
                        <Grid item container xl={4} lg={4} md={4} sm={4} xs={4}>
                            <Monster  width={'83%'} source={cha6} />
                        </Grid>
                        <Grid style={{display:'flex',justifyContent: 'end',}} item container xl={4} lg={4} md={4} sm={4} xs={4}>
                            <Monster width={'83%'} source={cha7} />     
                        </Grid>
                    </Grid>
                    <div className={classes.cardcontent1}>
                        <text className={classes.cardcontenttext1}>25%</text>
                        <text className={classes.cardcontenttext22}>
                        Our one of a kind <b>Seasonal NFT Mint Pass System</b> is unveiled. Your Wickens 
                        NFT mint pass will act as a guranteed ticket for massive future rewards 
                        (over 5 unique rewards are already planned, with many more to come).
                        </text>
                        <text className={classes.cardcontenttext2}>
                        Our illustrator is
                        currently working on
                        a debut graphic novel!
                        </text>
                    </div>
                    <div className={classes.cardcontent}>
                        <text className={classes.cardcontenttext1}>50%</text>
                        <text className={classes.cardcontenttext2}>
                        <b>(i)</b> The <b>Wickens Community Fund</b> is established! <b>33.33 ETH</b> is deposited into our community wallet. These funds will be used to add 
                        value to our brand and community through various ventures such as exclusive giveaways, community events/games, holders only competitions, 
                        airdrop giveaways, marketing initiatives and much, much more!
                        </text>
                        <text className={classes.cardcontenttext2}>
                        <b>(ii)</b> The <b>Wickens Development Fund</b> is also established! <b>33.33 ETH</b> is deposited into this pool to help us expand our team in order
                         to fully realise our vision of creating the expanded Wickens Universe as well as allowing us to push the limits of what an NFT can provide.
                        </text>
                        <text className={classes.cardcontenttext2} style={{fontStyle:'italic'}}> 
                        All of our outgoing transactions from the above wallets will be public and announced in detail (with proof of receipt) via our Discord to provide transparency to our community.
                        </text>
                    </div>
                    <div className={classes.cardcontent}>
                        <text className={classes.cardcontenttext1}>75%</text>
                        <text className={classes.cardcontenttext2}>
                        <b>(i)</b> We give holders a special sneak peek of our <b>Premium Merch Store.</b>
                        </text>
                        <text className={classes.cardcontenttext2}>
                        <b>(ii)</b> We will select 25 random Wickens NFTs that have been minted so far, and 
                        award the current owners of them <b>0.666 ETH each.</b>
                        </text>
                    </div>
                    <div className={classes.cardcontent}>
                        <text className={classes.cardcontenttext1}>100%</text>
                        <p className={classes.cardcontenttext3}>
                        <b>(i)</b> Our <b>Wickens Universe Graphic Novel </b>(already a work in progress) will be published and
                        released to all Wickens NFT holders. Minting 5 Wickens in total (during the presale/public sale) 
                        will put you into the running for an airdrop of a special 1/1 artist signed NFT of the graphic novel.
                        </p>
                        <p className={classes.cardcontenttext3}>
                        <b>(ii)</b> Further mint pass system rewards are unveiled. Rumors are that our Wickens are on the lookout for their <b>Pets...</b>
                        </p>
                        <p className={classes.cardcontenttext3}>
                        <b>(iii)</b> We want our community to have a say in terms of steering the development of the Wickens project. 
                        As such, we will hold a community vote to decide on <b>one major future milestone</b> that we will all embark on to achieve together. 
                        An animated web series, a P2E game, burning/fusion mechanics - <b>YOU</b> decide and we’ll work together to make it happen!
                        </p>
                        <p className={classes.cardcontenttext3}>
                        <b>(iv)</b> Our <b>Wickens Community Fund</b> and <b>Wickens Development Fund</b> are now both fully filled - <b>66.66 ETH</b> has been deposited into each!
                        </p>
                    </div>
                </Grid>
                
                
                    <Grid className={classes.card3} item lg={8} md={8} sm={8} xs={8}>
                        <p className={classes.cardcontenttext8}>
                            RARITIES
                        </p>
                    </Grid>   
                    <Grid className={classes.card3} item lg={7} md={7} sm={12} xs={12}>
                        <Monster width={'100%'} source={melon}/>
                    </Grid>     
                    <Grid className={classes.card3} item lg={8} md={8} sm={12} xs={12}>
                        <p className={classes.cardcontenttext2} style={{marginTop:'20px'}}>
                        We admit, not all Wickens are created aesthetically equal. 
                        Some have cameras, some are Elves, and some are unique 1/1s. 
                        Nevertheless, we believe there should be a perfect Wicken out 
                        there for everyone! Initially, each Wickens NFT will grant 
                        identical benefits for their holders, however, as Edgewick evolves, 
                        keep an eye out for some <b>timed trait-specific events...</b>
                    </p>
                    </Grid>   
                    <Grid className={classes.card3} item lg={12} md={12} sm={12} xs={12}>
                        <p id="saleDetails" className={classes.cardcontenttext8}>
                            SALE DETAILS
                        </p>
                    </Grid>
                    <Grid container className={classes.cardcontent7} item lg={12} md={12} sm={12} xs={12}>
                        <p className={classes.cardcontenttext2}>
                        <b>Presale:</b> 2000 early adopters will have the opportunity to mint up to 3 
                        Wickens NFTs each at the cost of 0.05 per mint. Thank you for starting 
                        the journey with us from the beginning!
                        </p>
                        <p className={classes.cardcontenttext2}>
                        <b>Public Sale:</b> The remaining Wickens NFTs left over from the presale will 
                        be available to the public at the same cost of 0.05 per mint. Each wallet
                        will be able to mint up to 5 Wickens NFTs per transaction, with a maximum
                        limit of 10 NFTs per wallet.
                        </p>
                        <p className={classes.cardcontenttext2} style={{fontStyle:'italic'}}>
                        100 Wickens will be reserved by our team for marketing purposes/future
                        giveaways.
                        </p>
                    </Grid>
                    <Grid className={classes.card3} item lg={12} md={12} sm={12} xs={12}>
                        <p className={classes.cardcontenttext8}>
                            WILL THERE BE A WHITELIST?
                        </p>
                    </Grid>
                    <Grid container className={classes.cardcontent7} item lg={12} md={12} sm={12} xs={12}>
                        <p className={classes.cardcontenttext2}>
                        We want to reward a small portion of our early and active community members. 
                        Therefore, as mentioned above, 2000 early adopters will be eligble to be whitelisted, 
                        granting them access to mint up to 3 Wickens NFTs during the presale. 
                        </p>
                        <p className={classes.cardcontenttext2}>
                        We understand that everyone has their own preferences, therefore we will 
                        provide a variety of methods for obtaining a presale spot. 
                        Our Discord will clearly outline the requirements for each of our 
                        whitelisting methods, so come join us over there!
                        </p>
                        <p className={classes.cardcontenttext2}>
                        A 48 hour presale time window will be provided to allow whitelisted 
                        community members to mint their Wickens NFTs at a time that suits
                        them (eg. when gas is low)
                        </p>  
                    </Grid>
                    <Grid container className={classes.bottomimage}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.cardcontenttext1} style={{marginTop:'20px',alignSelf:'center',textAlign:"center"}}>
                            <p id="teamDetail">TEAM</p>
                        </Grid>
                        <Grid className={classes.bottom} item xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Grid item xl={12} lg={12} md={12} sm={6} xs={6}>
                                <Monster width={'85%'} source={shanoot}/>
                            </Grid>
                            <Grid className={classes.bottomcontent} item xl={12} lg={12} md={12} sm={6} xs={6}>
                                <div className={classes.bottomText3}>
                                    <text className={classes.cardcontenttext1}>SHANNOOT</text>
                                    <Grid container style={{display:'flex',justifyContent:'center'}}>
                                        <img src={twitterIcon} style={{width:'30px', cursor:'pointer'}} onClick={()=>window.open("https://twitter.com/shannoot_art")}/>
                                        <img src={insta} style={{width:'30px',cursor:'pointer'}} onClick={()=>window.open("https://www.instagram.com/shannon_elizabeth_art/")}/>
                                    </Grid>
                                    <text className={classes.cardcontenttext3} style={{textAlign:'center'}}>
                                        FOUNDER
                                    </text>
                                    <text className={classes.cardcontenttext3} style={{textAlign:'center'}}>
                                        PENCIL WIELDING JESUS
                                    </text>
                                    <text className={classes.cardcontenttext3} style={{textAlign:'center'}}>
                                        SHIELD MAIDEN 
                                    </text>
                                </div>
                            </Grid>
                        </Grid> 
                        <Grid className={classes.bottom} item xl={4} lg={4} md={12} sm={12} xs={12}>
                            <Grid item xl={12} lg={12} md={12} sm={6} xs={6}>
                                <Monster width={'85%'}  source={rycoon}/>
                            </Grid>
                            <Grid className={classes.bottomcontent} item xl={12} lg={12} md={12} sm={6} xs={6}>
                                <div className={classes.bottomText3}>
                                    <text className={classes.cardcontenttext1} >RYAN-CHAN</text>
                                        <text className={classes.cardcontenttext3} style={{textAlign:'center'}}>
                                            CO-FOUNDER
                                        </text>
                                        <text className={classes.cardcontenttext3} style={{textAlign:'center'}}>
                                            MUSHROOM CONNOISEUR
                                        </text>
                                        <text className={classes.cardcontenttext3} style={{textAlign:'center'}}>
                                            THE "NUMBERS" GUY
                                        </text>
                                </div>
                            </Grid>
                        </Grid> 
                        <Grid className={classes.bottom} item xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Grid item xl={12} lg={12} md={12} sm={6} xs={6}>
                                <Monster width={'85%'}  source={steve}/>
                            </Grid>
                            <Grid className={classes.bottomcontent} item xl={12} lg={12} md={12} sm={6} xs={6}>
                                <div className={classes.bottomText3}>
                                    <text className={classes.cardcontenttext1} >S7EVEN</text>
                                    <text className={classes.cardcontenttext3} style={{textAlign:'center'}}>
                                        COMMUNITY MANAGER
                                    </text>
                                    <text className={classes.cardcontenttext3} style={{textAlign:'center'}}>
                                        TEAM OLD WORLD
                                    </text>
                                    <text className={classes.cardcontenttext3} style={{textAlign:'center'}}>
                                        THE "IDEAS" GUY
                                    </text>
                                </div>
                            </Grid>
                        </Grid> 
                    </Grid>
                    <Grid container sm={12} xs={12} style={{display:'flex',justifyContent:'center',textAlign:'center'}}>
                        <p className={classes.cardcontenttext3} style={{marginTop:'10vw',fontWeight:'bold',fontStyle:'italic'}}>Head over to our Discord and Twitter<br></br>
                                to keep up with the latest information on release dates, giveaways and more!</p>            
                    </Grid> 
                    <Grid container className={classes.bottomend}>
                        <img src={discordIcon} style={{width:'50px', marginRight:'30px',cursor:'pointer'}} onClick={()=>window.open("https://discord.gg/wickens")}/>
                        <img src={twitterIcon} style={{width:'50px',cursor:'pointer'}}  onClick={()=>window.open("https://twitter.com/WickensNFT")}/>
          
                    </Grid> 
            </Grid>
        </>
    )
}
