import React,{useEffect} from 'react'
import { makeStyles } from '@material-ui/core';
import discord from '../images/ICONdiscord.png';
import twitter from '../images/ICONtwitter.png';
import wallet from '../images/opensea.svg';

const useStyles = makeStyles((theme)=>({
    menucontain:{
        width:'100%',
        display:'flex',
        flexDirection:'row',
        marginTop:"100px",
        justifyContent:'space-around',
        '@media (max-width:1000px)':{
            zIndex:'10',
            width: '100%',
            marginTop:"0px",
            // justifyContent:'space-around',
        
        },
        
    },
    menuitem:{
        fontSize:'50px',
        fontFamily:"boogaloo",
        margin:'0 1px',
        color:'#efeee2',
        "&:hover":{
            color:"#eeae6e",
            cursor:"pointer"
          },
        '@media (max-width:1700px)':{
            margin:'0 0.2em',
        },
        '@media (max-width:1550px)':{
            fontSize:'35px',
            margin:'0 0.5em',
        },
        '@media (max-width:1280px)':{
            fontSize:'35px',
        },
        '@media (max-width:1180px)':{
            fontSize:'35px',
        },
        '@media (max-width:1090px)':{
            fontSize:'32px',
        },
        '@media (max-width:1000px)':{
            fontSize:'22px'
        }
        
    },
    socialIcon:{
        width:'50px',
        cursor:'pointer'
    },
    menuitem1:{
        fontSize:'42px',
        fontFamily:"boogaloo",
        margin:'0 0.3em',
        color:'#efeee2',
        "&:hover":{
            color:"#eeae6e",
            cursor:"pointer"
          },
        '@media (max-width:1000px)':{
            display:'none'
        },
         '@media (max-width:1700px)':{
            margin:'0 0.2em',
        },
        '@media (max-width:1550px)':{
            fontSize:'25px',
            margin:'0 0.5em',
        },
        '@media (max-width:1280px)':{
            fontSize:'35px',
        },
        '@media (max-width:1180px)':{
            fontSize:'35px',
        },
        '@media (max-width:1090px)':{
            fontSize:'32px',
        },
        
    }
}))

export default function MenuArea({modalOpen,about,roadmap,sales,team}) {

    const classes = useStyles();

     return (
        <div className={classes.menucontain}>
            <div className={classes.menuitem} onClick={()=>about.current.scrollIntoView({behavior: 'smooth'})}>ABOUT</div>
            <div className={classes.menuitem} style={{flexDirection:'row'}} onClick={()=>roadmap.current.scrollIntoView({behavior: 'smooth'})}>
              <text>ROAD</text>
              <text style={{marginLeft:'6px'}}>MAP</text>
            </div>
            <div className={classes.menuitem} onClick={()=>document.querySelector("#saleDetails").scrollIntoView({behavior: 'smooth'})}>SALES</div>
            <div className={classes.menuitem} onClick={()=>document.querySelector("#teamDetail").scrollIntoView({behavior: 'smooth'})}>TEAM</div>
        </div>
    )
}
