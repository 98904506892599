import React,{useEffect} from 'react'
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme)=>({
    togglecontent:{
        display:'none',
        '@media (max-width:1000px)':{
            display:'block',
            position:'absolute',
            right:'70px',
            top:'70px'
        }
    }
}));
export default function ToggleMenuIcon({
    setmodalOpen,modalOpen
}) {

    const classes = useStyles();

    return (
        <div className={classes.togglecontent}>
             <IconButton aria-label="delete" size="large" color="primary" onClick={()=>setmodalOpen(!modalOpen)}>
                <MenuIcon fontSize="large" />
            </IconButton>
        </div>
    )
}
